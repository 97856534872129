const NULLISH_VALUES = ['undefined', 'null', ''];

export const classNames = <T extends string = string>(
  ...list: (string | undefined | Record<string, boolean>)[]
): T =>
  list
    .filter(Boolean)
    .map((className) => {
      if (typeof className === 'string') return className;

      return Object.keys(className!)
        .filter((name) => !NULLISH_VALUES.includes(name) && Boolean(className![name]))
        .join(' ')
        .replace(/\s+/g, ' ')
        .trim();
    })
    .join(' ')
    .replace(/\s+/g, ' ')
    .trim() as T;
